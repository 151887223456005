@import "./../../core.scss";

.buy-tickets {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10vh;
    padding: 0 45px;

    .comic-text {
        text-align: center;
        font-family: 'Contrail One', cursive;
        font-size: remConverter(66);
        text-transform: uppercase;
        line-height: 76px;
        color: $purple;
        text-shadow: 4px 6px 0 #0A0A0C;
        -webkit-text-stroke: 2px #0A0A0C;
        padding: 0 42px;
        letter-spacing: -0.02em;
    }

    &__wrap {
        width: 100%;
        background: $green;
        border: 2px solid $black;
        box-shadow: 6px 8px 0 $black;
        border-radius: 12px;
        margin: 65px 0 32px;
        padding: 42px 0 0;
    }

    &__buttons {
        display: flex;
        gap: 24px;
        width: fit-content;
        margin: 42px auto;
    }

    .button {
        font-family: 'Open Sans', sans-serif;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        text-transform: uppercase;
        color: $black;
        background: $yellow;
        border: 2px solid $black;
        box-shadow: 4px 6px 0 $black;
        border-radius: 6px;
        padding: 6px 14px;
        cursor: pointer;
        height: fit-content;
    }
    .button:hover {
        background: $orange;
        box-shadow: 6px 8px 0px $black;
        font-style: normal;
    }
    .button:active {
        box-shadow: 2px 4px 0px $black;
        font-style: normal;
    }

    &__form {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;

        background: #1B1B1B;
        color: #ffc23a;
        max-width: 634px;
        width: 100%;
        box-shadow: 12px 9px 7px rgba(0, 0, 0, 0.18);
        border-radius: 23px;

        padding: 30px;
    }
    &__bg {
        // background-image: url("./../../assets/images/bg.svg");
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;

        height: 630px;
        width: 100%;
    }
    &__info {
        font-size: 42px;
        line-height: 55px;
        letter-spacing: 0.01em;
        text-transform: uppercase;

        color: $primary-dark;

        margin: 47px auto 24px;
    }
    &__title {
        font-size: remConverter(66);
        font-family: 'Contrail One', cursive;
        text-transform: uppercase;
        line-height: 76px;
        text-align: center;
        margin-bottom: 4px;
        letter-spacing: -0.02em;

        color: $yellow;
        text-shadow: 6px 8px 0 #0A0A0C;
        -webkit-text-stroke: 2px #0A0A0C;
    }
    &__label {
        font-family: 'Open Sans', sans-serif;
        text-align: center;
        font-weight: 700;
        font-size: 20px;
        line-height: 32px;
        letter-spacing: -0.04em;
    }

    & .subscribe {
        background: $green;
        &__input {
            color: $primary-dark;
            border-bottom: 2px solid $primary-dark;
            background: $green;
            margin-right: 22px;
            font-weight: 400;
            font-family: 'Open Sans';
            &:-webkit-autofill,
            &:-webkit-autofill:active,
            &:-webkit-autofill:hover,
            &:-webkit-autofill:focus {
                -webkit-text-fill-color: $primary-dark;
                -webkit-box-shadow: 0 0 0px 1000px $green inset;
                transition: background-color 5000s ease-in-out 0s;
            }

            &:active,
            &:focus { background: $green; }

            &::placeholder { color: $primary-dark; }
        }
        &__notification { color: $primary-dark; }
        &__sucess { color: $primary-dark; }
        .subscribe__error {
            color: $secondary-red;
            font-family: 'Open Sans';
        }
    }

    @include scale_from_to($tablet, $min-size) {
        align-items: start;
        padding: 0 16px;
        margin-top: 28px;
        &__title{
            font-size: 36px;
            line-height: 48px;
            text-shadow: 2px 4px 0 #0A0A0C;
            -webkit-text-stroke: 1px #0A0A0C;
            margin: 0 0 6px;
            text-align: left;
        }
        &__label {
            text-align: left;
        }
        & .subscribe {
            padding: 0 0 50px;
            .subscribe__wrap { button { max-width: 100%; } }
            &__input { margin-right: 0 }
        }
        &__wrap {
            margin: 46px 0 22px;
            padding: 20px 16px;
            box-sizing: border-box;
            overflow-x: hidden;
            & .comic-text {
                font-size: 36px;
                text-align: left;
                padding: 0;
                line-height: 48px;
                text-shadow: 2px 4px 0 #0A0A0C;
                -webkit-text-stroke: 1px #0A0A0C;
            }
            & .buy-tickets__buttons {
                flex-direction: column;
                margin: 42px auto 0;
            }
            & .button {
                font-size: 20px;
                line-height: 32px;
                white-space: break-spaces;
            }
            & .subscribe__error-field {
                margin: 5px auto;
            }
        }
    }
}
