@import "./../../core.scss";

.bitcoinWeek,
.rigaHotels {
  display: flex;
  gap: 32px;
  justify-content: center;
  padding: 62px 0;
  align-items: center;

  &__content {
    max-width: 660px;
    width: 50%;

    &__title {
      font-weight: 700;
      padding: 24px 0;
      margin-bottom: 24px;
      border-bottom: 2px solid $black;
      font-size: 66px;
      line-height: 70px;
      width: 100%;
      color: $yellow;

      @include title();
    }

    &__text {
      font-weight: 700;
      font-size: 26px;
      line-height: 40px;
      margin: 0 0 24px;

      &__bolder {
        font-weight: 800;
      }
    }

    &__img {
      width: 100%;
      border: 1px solid $black;
      height: 540px;
      object-fit: cover;
      object-position: top;
    }
  }

  &__button {
    background: $purple;
    border: 2px solid $black;
    border-radius: 6px;
    box-shadow: 4px 6px 0 $black;
    padding: 8px 0;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    display: block;
    text-transform: uppercase;
    width: 100%;
    &__link {
      display: flex;
      gap: 10px;
      justify-content: center;
      text-decoration: none;
      color: $black;
    }
    &:hover {
      background: $orange;
      box-shadow: 6px 8px 0px $black;
    }
    &:active {
      box-shadow: 2px 4px 0px $black;
    }
  }
}

.rigaHotels__content {
  &__text {
    min-height: 175px;
  }
  &__logos {
    display: flex;
    justify-content: space-between;
  }
  &__logos__img {
    padding: 20px;
  }
  &__img {
    border: 1px solid $black;
    height: 520px;
    object-position: left top;
    object-fit: cover;
  }
}

@media screen and (max-width: 1200px) {
  .bitcoinWeek,
  .rigaHotels {
    padding: 24px 0;

    &__content {
      max-width: unset;
      width: 100%;
      &__title {
        font-size: 36px;
        line-height: 48px;
        padding: 5px 0;
      }
      &__title::after {
        top: 6px;
      }
      &__img {
        margin-bottom: 24px;
      }

      &__text {
        margin-bottom: 24px;
        font-size: 20px;
        line-height: 32px;
      }
    }
  }

  .rigaHotels__content {
    &__text {
      min-height: unset;
    }
    &__logos {
      margin-bottom: 24px;
      display: flex;
      justify-content: space-between;
    }
  }
}

@media screen and (max-width: 640px) {
  .rigaHotels__content {
    &__logos {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 24px;
      &__img {
        max-width: 220px;
      }
    }
    &__img {
      height: 280px;
    }
  }

  .bitcoinWeek__content__img {
    height: 280px;
  }
}
