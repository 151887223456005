@import "./../../core.scss";

.sponsors {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: unset;
  &__title {
    font-family: "Contrail One", cursive, Arial, sans-serif;
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 0.09em;
    width: fit-content;
    font-weight: 400;
    letter-spacing: -0.02em;
    font-size: 92px;
    line-height: 110px;
    color: $yellow;
    text-shadow: 6px 8px 0 $black;
    -webkit-text-stroke: 2px $black;
    position: relative;
    margin-top: 54px;

    &::after {
      content: "2024 Sponsorship tiers";
      text-shadow: 6px 8px 0 $black;
      -webkit-text-stroke: 2px $black;
      position: absolute;
      left: 0;
      top: 0;
      width: fit-content;
    }
  }
  &__subtitle {
    font-size: 20px;
    line-height: 32px;
    text-align: center;
    color: $primary-dark;

    &.general {
      max-width: 585px;
      font-weight: 700;
    }
  }
  &__note {
    margin-top: 90px;
    display: flex;
    align-items: center;
    gap: 12px;
    &__text {
      font-size: 30px;
      line-height: 38px;
      font-weight: 500;
      color: $primary-dark;
    }
  }
  @include scale_from_to($tablet, $min-size) {
    &__title {
      font-size: 36px;
      line-height: 45px;
      letter-spacing: 0.03em;
    }
  }
}

.sponsors-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-top: 62px;

  &__title {
    font-size: 46px;
    line-height: 60px;
    color: $primary-dark;
    font-weight: 500;
    text-align: center;
  }
  &__subtitle {
    font-size: 16px;
    line-height: 24px;
    color: $primary-dark;
    text-align: center;
    margin-top: 6px;
    min-width: 360px;
    width: max-content;
    margin-bottom: 24px;
    max-width: 100%;

    &.main-block {
      max-width: 409px;
    }
  }
}

.main-cards {
  display: grid;
  grid-auto-flow: column;
  align-items: stretch;
  justify-content: space-evenly;
  width: 100%;
  margin: 0 auto 0;
  grid-row-gap: 24px;
  grid-column-gap: 26px;
}
.card {
  width: 352px;
  background: #ffffff;
  border: 2px solid $black;
  box-shadow: 4px 6px 0px $black;
  border-radius: 12px;
  margin: 5px;
  position: relative;
  overflow: hidden;

  .sold_out {
    display: none;
  }

  &.sold {
    & .sold_out {
      height: 100%;
      width: 100%;
      background: rgba(11, 10, 11, 0.76);
      position: absolute;
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      &__title {
        color: $yellow;
        font-size: 36px;
        line-height: 48px;
        font-family: "Contrail One", cursive, Arial, sans-serif;
        text-transform: uppercase;
        letter-spacing: -0.02em;
        text-shadow: 1px 2px 0 $black;
        -webkit-text-stroke: 1px $black;

        &::after {
          content: "SOLD OUT";
          text-shadow: 6px 8px 0 $black;
          -webkit-text-stroke: 2px $black;
          position: absolute;
          left: 0;
          top: 0;
          width: fit-content;
        }
      }
    }
  }

  &__header {
    width: 100%;
    height: 75px;

    box-sizing: border-box;
    margin-top: 0px;
    border-bottom: 2px solid $primary-dark;

    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    &.exclusive {
      justify-content: space-between;
      & img {
        align-self: center;
        padding-right: 28px;
      }
    }

    &.blue {
      background: $green;
    }
    &.black {
      background: $primary-dark;
    }
    &.red {
      background: $red;
    }
  }
  &__title {
    font-size: 36px;
    line-height: 38px;
    color: #ffc239;
    font-family: "Contrail One", cursive, Arial, sans-serif;
    text-transform: uppercase;
    padding: 22px 0 0px 21px;
    font-weight: 500;
    text-shadow: 2px 3px 0 #0b0a0b;
    -webkit-text-stroke: 1px #0b0a0b;
  }
  &__body {
    padding: 20px 28px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    flex: 1 0 auto;
  }
  &__money {
    font-weight: bold;
    font-size: 46px;
    line-height: 55px;
    color: $primary-dark;
  }
  &__description {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.2px;

    color: $primary-dark;
    padding: 3px 0px 15px;
  }
  &__descriptionAddition {
    font-size: 12px;
  }
  &__list-title {
    font-weight: bold;
    font-size: 20px;
    line-height: 26px;

    color: $primary-dark;
    margin-top: 16px;
  }
  &__list {
    flex: 1 0 auto;
    margin-top: 6px;
  }
  &__elem {
    font-size: 16px;
    line-height: 23px;

    color: $primary-dark;
    list-style-type: none;
    display: grid;
    grid-auto-columns: 30px auto;
    grid-auto-rows: 1fr;
    justify-items: flex-start;
    grid-auto-flow: column;
    align-items: flex-start;
    padding: 0;
    // grid-template-columns: repeat(3, 1fr) 0 1fr;

    &::before {
      content: "";
      background: url("./../../assets/images/sponsors-icons/check.svg");
      width: 20px;
      height: 20px;
      display: block;
      margin: 0px 0px 8px 0px;
      background-repeat: no-repeat;
      padding: 0 4px;
    }
  }
  &__info {
    margin-top: 28px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  &__span {
    font-size: 16px;
    line-height: 23px;
    color: $primary-dark;

    &:first-child {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      &::before {
        content: "";
        background: url("./../../assets/images/sponsors-icons/booth.svg");
        width: 20px;
        height: 20px;
        display: block;
        margin: 8px 10px 8px 0;
      }
    }
    &:nth-child(2) {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      &::before {
        content: "";
        background: url("./../../assets/images/sponsors-icons/newsletters.svg");
        width: 18px;
        height: 15px;
        display: block;
        margin: 8px 10px 8px 0;
      }
    }
    &:last-child {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      &::before {
        content: "";
        background: url("./../../assets/images/sponsors-icons/no.svg");
        width: 20px;
        height: 20px;
        display: block;
        margin: 8px 10px 8px 0;
      }
      &.bag {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        &::before {
          content: "";
          background: url("./../../assets/images/sponsors-icons/bag.svg");
          width: 20px;
          height: 20px;
          display: block;
          margin: 8px 10px 8px 0;
        }
      }
    }
  }
}

.inner-cards {
  display: grid;
  grid-auto-flow: column;
  align-items: stretch;
  justify-content: center;
  width: 100%;
  grid-row-gap: 24px;
  grid-column-gap: 38px;

  // margin-top: 24px;
}
.inner-card {
  width: 352px;
  background: #ffffff;
  border: 2px solid $primary-dark;
  box-shadow: 4px 6px 0px $black;
  border-radius: 12px;
  position: relative;

  &.hidden {
    .exclusive-border {
      border-bottom: none;
      display: none;
    }
  }
  .sold_out {
    display: none;
  }

  &.sold {
    & .sold_out {
      max-height: 600px;
      height: 100%;
      width: 100%;
      background: rgba(11, 10, 11, 0.76);
      position: absolute;
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      &__title {
        @include title();

        color: $yellow;
        font-size: 36px;
        line-height: 48px;
        font-weight: 700;
        text-transform: uppercase;
        text-shadow: none;

        &::after {
          content: "SOLD OUT";
          -webkit-text-stroke: 2px $black;
          position: absolute;
          left: 0;
          top: 0;
          width: fit-content;
        }
      }
    }
  }
  &__header {
    display: flex;
    align-items: flex-end;
    width: 100%;
    border-radius: 9px 9px 0px 0px;
    padding-bottom: 18px;
    border-bottom: 2px solid $primary-dark;
    height: 152px;
    justify-content: space-between;

    &.blue {
      background: $green;
    }
    &.black {
      background: $primary-dark;
    }
    &.red {
      background: $red;
    }
    &.orange {
      background: $orange;
    }
  }
  &__exclusive-ofder {
    width: 100%;
    height: 28px;
    padding: 4.5px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    background: $black;
    color: $yellow;
    font-weight: 700;
    font-size: 14px;
    text-transform: uppercase;
  }
  .exclusive-border {
    border-bottom: 2px solid black;
    width: 352px;

    @include scale_from_to($tablet, $min-size) {
      width: 348px;
    }
  }
}

.element {
  height: 100%;
}

.rows-2 {
  grid-template-rows: repeat(2, 1fr);
}
.rows-3 {
  grid-template-rows: repeat(3, 1fr);
}
.rows-4 {
  grid-template-rows: repeat(4, 1fr);
}

.offers {
  max-width: 1200px;
  width: 100%;
  .separator {
    width: 200px;
    display: flex;
    flex-basis: 100%;
    margin: 50px auto;
  }
}

.article-swiper {
  padding-left: 50px;
  max-width: $desktop;
  margin: 0 auto;
  .block {
    width: 100%;
  }
  @include scale_from_to(1024px, $tablet) {
    padding: 0px 50px;
    .block__image {
      width: 100%;
      height: 248px;
    }
  }
  @include scale_from_to($tablet, $min-size) {
    padding: 50px 20px;
  }
}

@include scale_from_to($tablet, $min-size) {
  .multi-toggle-container {
    flex-direction: column;
    border: none;
  }
  .sponsors {
    &__subtitle {
      font-size: 18px;
      line-height: 23px;
      text-align: center;

      color: $primary-dark;
    }
    &__title {
      margin-top: 70px;
      &::after {
        display: none;
      }
    }
  }

  .slick {
    &-slider {
      padding: 20px 0;
    }
    &-list {
      width: 360px !important;
      padding: 30px 20px !important;
    }
    &-slide {
      width: 340px;
      margin: 10px;
    }
  }
  .sponsors-block {
    &__title {
      font-size: 32px;
      line-height: 42px;

      text-align: center;
    }
    &__subtitle {
      font-size: 18px;
      line-height: 23px;
      text-align: center;
      width: min-content;
      max-width: fit-content;
    }
  }
  .rows-2,
  .rows-3,
  .rows-4 {
    grid-template-rows: 1fr;
  }
  .sponsor-swiper {
    width: 352px;
    padding-bottom: 0px;

    .card {
      width: auto;
      height: 726px;
    }
  }
  .swiper {
    padding-bottom: 30px;
    width: 352px;

    .inner-card {
      width: revert;
    }
  }
  #booth {
    .inner-card {
      height: 579px;
    }
  }
  #digital,
  #events {
    .inner-card {
      height: 469px;
    }
  }
  #conference {
    .inner-card {
      height: 463px;
    }
  }
}

.caonference-first-box,
.caonference-second-box {
  display: flex;
  justify-content: center;
  gap: 38px;
}

.caonference-second-box {
  margin-top: 24px;
}

@include scale_from_to(1200px, $tablet) {
  .offers {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .inner-cards,
  .main-cards {
    grid-template-columns: repeat(2, 1fr);
    grid-auto-flow: dense;
  }

  .caonference-first-box,
  .caonference-second-box {
    display: flex;
    justify-content: center;
    gap: 24px 38px;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .caonference-second-box {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-flow: dense;
  }
}

.swiper-pagination-bullets {
  bottom: 0px !important;
}

.swiper-pagination-bullet {
  width: 12px !important;
  height: 12px !important;
}

.swiper-pagination-bullet-active {
  background-color: $primary-dark !important;
}

.events-container {
  display: flex;
  flex-wrap: wrap;
}

@media screen and (max-width: 1200px) {
  .sponsors {
    &__title {
      font-size: 66px;
      line-height: 76px;
      letter-spacing: -0.02em;
      text-shadow: 4px 6px 0 $black;
      -webkit-text-stroke: 2px $black;
      &::after {
        text-shadow: 4px 6px 0 $black;
        -webkit-text-stroke: 2px $black;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .sponsors {
    &__title {
      font-size: 36px;
      line-height: 48px;
      letter-spacing: -0.02em;
      text-shadow: 2px 4px 0 $black;
      -webkit-text-stroke: 2px $black;
      margin-top: 20px;
      &::after {
        text-shadow: 2px 4px 0 $black;
        -webkit-text-stroke: 2px $black;
      }
    }
  }
}
