@import "./../../core.scss";

.footer {
  display: flex;
  justify-content: space-between;
  gap: 12px;
  margin: 76px auto 0;
  padding: 42px 15px;

  &__block {
    display: flex;
    flex-direction: column;
    gap: 12px;

    &:first-of-type {
      max-width: 224px;
      padding-right: 20px;

      a {
        text-decoration: none;
        color: $black;
        white-space: nowrap;
      }
    }

    &__item__link {
      width: fit-content;

      &::after {
        content: "";
        display: block;
        height: 1px;
        width: 0;
        background: transparent;
        transition: width 0.7s ease, background-color 0.5s ease;
      }

      &:hover:after {
        width: 100%;
        background: $black;
      }
    }

    &__item__link__icon {
      vertical-align: middle;
      margin-right: 6px;
      display: inline-flex;
    }

    &__item__link a {
      display: inline-flex;
      align-items: center;
      vertical-align: top;
    }

    &__right {
      max-width: 244px;
      align-items: end;

      a {
        text-decoration: none;
        color: $black;
      }
    }

    &__title {
      font-size: 66px;
      color: $yellow;
      width: 100%;

      @include title();
    }
  }
}

@media screen and (max-width: 1200px) {
  .footer {
    margin: 42px auto 0;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 12px 60px;

    &__block {
      width: 100%;
      order: 1;

      &:first-of-type {
        order: 2;
      }

      &__right {
        text-align: left;
        order: 3;
        max-width: 244px;
        align-items: unset;

        a {
          text-decoration: none;
          color: $black;
        }
      }

      &__title {
        font-size: 36px;
        line-height: 48px;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .footer {
    margin: 0 auto;

    &__block {
      &:first-of-type {
        order: 3;
        gap: 16px;
        max-width: unset;
        padding-right: 0;
      }
      &__right__mobile {
        display: flex;
        flex-direction: column;
        gap: 12px;

        .footer__block__item__link {
          min-width: 160px;
        }
      }
      &__right {
        display: flex;
        flex-direction: row;
        gap: 4px;
        text-align: left;
        order: 2;
        margin-bottom: 12px;

        a {
          text-decoration: none;
          color: $black;
        }
      }
      &__title {
        line-height: 44px;
        margin-bottom: 14px;
        letter-spacing: 0;
      }
    }
  }
}
