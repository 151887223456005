@import "./../../core.scss";

.agenda {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 32px;
  padding: 0 45px;

  &__title {
    font-size: remConverter(66);
    line-height: 76px;
    text-align: center;
    color: $yellow;

    @include title();
  }

  &__subtitle {
    font-family: "Open Sans", sans-serif;
    text-align: center;
    font-weight: 700;
    font-size: 20px;
    line-height: 32px;
    letter-spacing: -0.04em;
    color: $primary-dark;
    margin: 6px 0 0;
    max-width: 960px;
  }

  &__content {
    max-width: 1160px;
    width: 100%;
    border-radius: 12px;
    border: 2px solid #0b0a0b;
    box-shadow: 6px 8px 0px 0px #0b0a0b;

    &__item {
      width: 100%;
      display: flex;
      border-bottom: 2px solid #0b0a0b;

      &:last-child {
        border-bottom: none;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
      }

      &:first-child &__title {
        border-top-left-radius: 8px;
      }

      &:last-child &__title {
        border-bottom-left-radius: 8px;
      }

      &:first-child &__stage {
        border-top-right-radius: 8px;
      }

      &:last-child &__stage {
        border-bottom-right-radius: 8px;
      }

      &.lunch {
        padding: 24px;
        background: #f33a3e;
        box-sizing: border-box;
        display: flex;
        gap: 24px;
      }

      &__lunch__time,
      &__lunch__title {
        @include font("Contrail One", 36px, 48px);
        @include font("Contrail One", 36px, 48px, 400);
        letter-spacing: -0.02em;
        position: relative;
        text-transform: uppercase;
        text-shadow: 4px 4px 0 $primary-dark, -1px -1px 0 $primary-dark,
          1px -1px $primary-dark, -1px 1px $primary-dark, 0px -1px $primary-dark,
          -1px 0px $primary-dark, 0px 1px $primary-dark, 1px 0px $primary-dark;
        color: #ffb52e;
      }

      &__title {
        width: 170px;
        padding: 24px;
        border-right: 2px solid #0b0a0b;

        &.main {
          background: #ffb52e;
        }

        &.cypherpunk {
          background: #0b0a0b;
        }

        &.cypherpunk &__time,
        &.cypherpunk &__subtitle {
          color: #ffb52e;
          font-weight: 700;
        }

        &.pow {
          background: #5dbead;
        }

        &__time {
          font-size: 26px;
          font-weight: 700;
          line-height: 40px;
        }

        &__subtitle {
          font-size: 20px;
          line-height: 32px;
          font-weight: 700;
        }
      }

      &__text,
      &__stage {
        background: white;
      }

      &__text {
        width: calc(100% - 562px);
        padding: 24px;

        &__title {
          font-size: 26px;
          font-weight: 700;
          line-height: 40px;
        }

        &__author {
          font-size: 20px;
          line-height: 32px;
          text-transform: capitalize;
        }
      }

      &__stage {
        width: 270px;
        display: flex;
        align-items: center;
        justify-content: end;
        padding: 24px 24px 24px 0;

        &__title {
          @include font("Contrail One", 36px, 48px, 400);
          letter-spacing: -0.02em;
          position: relative;
          text-transform: uppercase;
          text-shadow: 4px 4px 0 $primary-dark, -1px -1px 0 $primary-dark,
            1px -1px $primary-dark, -1px 1px $primary-dark,
            0px -1px $primary-dark, -1px 0px $primary-dark,
            0px 1px $primary-dark, 1px 0px $primary-dark;

          &.main {
            color: #5dbead;
          }

          &.cypherpunk {
            color: #ffb52e;
          }

          &.pow {
            color: #c683f6;
          }
        }
      }
    }
  }

  &__types {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-bottom: 46px;

    &__day {
      display: flex;
      gap: 20px;
      justify-content: center;

      &__item {
        cursor: pointer;
        padding: 8px;
        border-radius: 12px;
        border: 1px solid #0b0a0b;
      }

      & .active {
        font-weight: 700;
        background: #ffb52e;
        box-shadow: 2px 4px 0px 0px #0b0a0b;
      }
    }

    &__type {
      display: flex;
      gap: 12px;
      justify-content: center;

      &__item {
        cursor: pointer;
        padding: 8px;
        border-radius: 12px;
        border: 1px solid #0b0a0b;
      }

      & .active {
        font-weight: 700;
        background: #5dbead;
        box-shadow: 2px 4px 0px 0px #0b0a0b;
      }
    }
  }

  @include scale_from_to($bigTablet, $tablet) {
    &__content {
      &__item {
        &__text {
          width: calc(100% - 500px);
        }

        &__stage {
          width: 280px;
        }
      }
    }
  }

  @include scale_from_to($tablet, $min-size) {
    align-items: start;
    padding: 0;
    margin-top: 28px;

    &__title {
      font-size: 36px;
      line-height: 48px;
      text-shadow: 2px 4px 0 #0a0a0c;
      -webkit-text-stroke: 1px #0a0a0c;
      text-align: left;
    }

    &__subtitle {
      text-align: left;
      margin-bottom: 0;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      margin: 0;
    }

    &__types {
      gap: 24px;
      margin-bottom: 26px;

      &__day {
        gap: 12px;
        justify-content: start;
      }

      &__type {
        gap: 12px;
        justify-content: start;
        flex-wrap: wrap;
      }
    }

    &__content {
      max-width: 1160px;
      width: 100%;
      border-radius: 12px;
      border: 2px solid #0b0a0b;
      box-shadow: 6px 8px 0px 0px #0b0a0b;

      &__item {
        flex-direction: column;

        &:first-child &__title {
          border-top-right-radius: 8px;
        }

        &:last-child &__title {
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
        }

        &:last-child &__text {
          border-bottom-left-radius: 8px;
        }

        &.lunch {
          padding: 16px;
          box-sizing: border-box;
          flex-direction: row;
          justify-content: space-between;
        }

        &__lunch__time {
          font-size: 26px;
          font-weight: 700;
          line-height: 40px;
          color: #0b0a0b;
          margin-top: 2px;
          font-family: Open Sans;
          text-transform: none;
          width: fit-content;
          letter-spacing: unset;
          text-shadow: unset !important;
          -webkit-text-stroke: unset !important;
        }

        &__title {
          width: 100%;
          box-sizing: border-box;
          padding: 16px;
          border-right: none;
          position: relative;
          border-bottom: 2px solid #0b0a0b;

          &.cypherpunk {
            height: 128px;

            .agenda__content__item__title__time {
              margin-bottom: 20px;
            }

            .agenda__content__item__title__subtitle {
              font-weight: 400;
            }
          }

          &__type {
            @include font("Contrail One", 36px, 48px, 400);
            letter-spacing: -0.02em;
            text-transform: uppercase;
            text-shadow: 4px 4px 0 $primary-dark, -1px -1px 0 $primary-dark,
              1px -1px $primary-dark, -1px 1px $primary-dark,
              0px -1px $primary-dark, -1px 0px $primary-dark,
              0px 1px $primary-dark, 1px 0px $primary-dark;
            position: absolute;
            top: 16px;
            right: 16px;
            max-width: 160px;
            text-align: right;

            &.main {
              color: #5dbead;
            }

            &.cypherpunk {
              color: #ffb52e;
            }

            &.pow {
              color: #c683f6;
            }
          }
        }

        &__text {
          width: 100%;
          box-sizing: border-box;
          padding: 12px 16px 24px;

          &__title {
            margin-bottom: 6px;
          }
        }
      }
    }
  }
}

.agenda-schedule {
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
  margin: 0 auto;
  overflow: scroll;

  @include scale_from_to($tablet, $min-size) {
    margin: 0;
    padding: 0;
    overflow: unset;
  }
}

.agenda-schedule::-webkit-scrollbar {
  background-color: white;
}

