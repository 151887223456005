@import "./../../core.scss";

.bitcoin-week {
  padding: 16px 44px;
  margin-bottom: 168px;

  &__container {
    margin: 40px auto 35px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__title {
    position: relative;
    color: $yellow;

    font-style: normal;
    font-size: 66px;
    line-height: 76px;

    @include title();
  }

  &__subtitle {
    @include font("Open Sans", 20px, 32px, 700);
    margin-top: 8px;
    text-align: center;
    color: $black;
  }

  .days {
    &__title {
      @include font("Open Sans", 46px, 68px, 700);
      text-align: center;
      color: $primary-dark;
    }

    &__subtitle {
      @include font("Open Sans", 16px, 24px, 400);
      text-align: center;
      color: $primary-dark;
    }

    &.noob-day .red {
      height: 192px;
    }

    &.layer {
      margin-top: 86px;
    }

    &.layer .days-block {
      min-height: 287px;
      &__head {
        border-bottom: none;
        &__title {
          position: relative;
          z-index: 2;
        }
      }
      &__info {
        position: relative;
        background-color: white;
        z-index: 2;
        border-top: 2px solid $primary-dark;
        border-bottom-left-radius: 14px;
        border-bottom-right-radius: 14px;
      }
    }

    &.mainafterparty {
      & .days-block {
        &__head {
          a {
            color: white;
          }
        }
      }
    }

    &.hosted {
      margin-top: 92px;
      & .days-block {
        &__head {
          a {
            color: white;
          }
        }
      }
    }

    &.hosted .days-block {
      min-height: 184px;
    }

    &.nostrparty .days-block {
      min-height: 187px;

      &__head {
        a {
          color: $white;
        }
      }
    }

    &.nostrparty .days-block__info__title {
      position: relative;
      z-index: 2;
    }

    &.twentyone {
      a {
        color: $white;
      }

      & .days-block {
        &__head {
          h3 {
            color: $green;
          }

          .clock-color::before {
            background: url("./../../assets/images/blueClock.svg");
          }

          .ticket-color::before {
            background: url("./../../assets/images/blueTicket.svg");
          }

          .location-color::before {
            background: url("./../../assets/images/blueLocation.svg");
          }
        }
      }
    }

    & .info-image {
      margin-top: 24px;
      &.baltic-mining {
        display: block;
        margin: 0 auto 0;
        position: relative;
        bottom: -32px;
      }
      &.lights {
        display: block;
        margin: 0 0 0 auto;
        right: -16px;
        position: relative;
        bottom: -32px;
      }
    }
  }

  .days-block {
    display: flex;
    flex-direction: row;
    //background-color: #FFC239;
    width: 100%;
    max-width: 1344px;
    margin: 18px auto 0;
    background: #ffffff;
    border: 2px solid $primary-dark;
    box-shadow: 4px 6px 0 $black;
    border-radius: 12px;
    position: relative;

    &__head {
      flex-basis: 258px;
      min-width: 258px;
      padding: 24px;
      border-right: 2px solid $primary-dark;
      border-top-left-radius: 9px;
      border-bottom-left-radius: 9px;
    }

    &__head--image {
      background-size: cover;
    }

    &__head.black-purple {
      p {
        color: $white;
      }
      h3 {
        color: $purple;
      }
      .clock-color::before {
        background: url("./../../assets/images/purpleClock.svg");
      }

      .ticket-color::before {
        background: url("./../../assets/images/purpleTicket.svg");
      }

      .location-color::before {
        background: url("./../../assets/images/purpleLocation.svg");
      }
    }
    &__head.red {
      background-color: $red;
    }
    &__head.blue {
      background-color: $green;

      h3 {
        margin-bottom: 14px;
      }
    }
    &__head.gray {
      background-color: #2c2a2a;
      p {
        color: white;
      }

      h3 {
        color: #6baf4e;
      }

      .clock-color::before {
        background: url("./../../assets/images/greenClock.svg");
      }

      .ticket-color::before {
        background: url("./../../assets/images/greenTicket.svg");
      }

      .location-color::before {
        background: url("./../../assets/images/greenLocation.svg");
      }
    }
    &__head.black {
      background-color: $primary-dark;

      .days-block__head-item--location {
        a {
          color: white;
        }
      }

      p {
        color: white;
      }

      h3 {
        margin-bottom: 4px;
      }

      a {
        margin-top: 12px;
        width: 234px;
      }
    }
    &__head.yellow {
      background-color: $yellow;

      h3 {
        color: $green;
      }

      // a {
      //   background-color: $green;
      // }

      .clock-color::before {
        background: url("./../../assets/images/blueClock.svg");
      }

      .ticket-color::before {
        background: url("./../../assets/images/blueTicket.svg");
      }

      .location-color::before {
        background: url("./../../assets/images/blueLocation.svg");
      }
    }

    &__head__list {
      position: relative;
      z-index: 2;
    }

    &__head__title {
      @include font("Contrail One", 36px, 39px, 400);
      letter-spacing: -0.02em;
      position: relative;
      text-transform: uppercase;
      text-shadow: 4px 4px 0 $primary-dark, -1px -1px 0 $primary-dark,
        1px -1px $primary-dark, -1px 1px $primary-dark, 0px -1px $primary-dark,
        -1px 0px $primary-dark, 0px 1px $primary-dark, 1px 0px $primary-dark;
      color: $yellow;
    }

    &__head__subtitle {
      @include font("Open Sans", 16px, 24px, 400);
      color: $primary-dark;
    }

    &__head__button {
      box-sizing: border-box;
      min-width: 203px;
      height: 43px;
      margin-top: 18px;
      color: $black;
      background: $yellow;
      border: 2px solid $black;
      box-shadow: 4px 6px 0 $black;
      padding: 0 18px;
      border-radius: 6px;
      @include font("Open Sans", 20px, 27px, 700);
      text-transform: uppercase;
      text-decoration: none;
      display: inline-flex;
      align-items: center;
      justify-content: center;

      &:hover {
        background-color: $orange;
      }

      &:active {
        box-shadow: 2px 4px 0 $black;
      }
    }

    &__head-item {
      @include font("Open Sans", 16px, 24px, 400);
      margin-top: 2px;
      color: $primary-dark;

      &::before {
        content: "";
        margin-right: 4px;
        width: 24px;
        height: 24px;
        display: inline-block;
        vertical-align: middle;
      }
    }

    &__head-item--clock {
      margin-bottom: 12px;

      &::before {
        background: url("./../../assets/images/clock.svg");
      }
    }

    &__head-item--clock.no-space {
      margin-bottom: 0;
    }

    &__head-item--ticket::before {
      background: url("./../../assets/images/ticket.svg");
    }

    &__head-item--location a {
      color: $primary-dark;
    }

    &__head-item--location::before {
      background: url("./../../assets/images/location.svg");
    }

    &__info {
      padding: 24px;

      &__title {
        @include font("Open Sans", 26px, 34px, 700);
      }

      &__description {
        @include font("Open Sans", 16px, 24px, 400);
        margin-top: 15px;
        max-width: 700px;
        position: relative;
        z-index: 2;

        a {
          color: $primary-dark;
        }

        .mb-32 {
          margin-bottom: 32px;
        }

        ul {
          padding-left: 24px;

          li {
            list-style: disc;
          }

          .semibold {
            font-weight: 600;
          }
        }
      }

      &__btcpayServer {
        margin-top: 46px;
        width: 236.18px;
        height: 102.3px;
      }

      &__button {
        display: none;
      }
    }

    &.all-black {
      background-color: #0b0a0b;
      color: $white;
    }

    &.all-black .days-block__head p {
      color: $white;
    }

    &.all-black .days-block__info__description {
      max-width: unset;
    }
  }

  @media screen and (max-width: 640px) {
    padding: 16px;
    margin-bottom: 26px;

    &__container {
      margin-top: -18px;
      margin-bottom: 58px;
    }

    &__title {
      text-align: left;
      font-size: 36px;
      line-height: 48px;
      margin-top: 22px;
      letter-spacing: -0.035em;
    }

    &__subtitle {
      margin-top: 0;
      @include font("Open Sans", 16px, 24px, 400);
    }

    .days__title {
      @include font("Open Sans", 26px, 40px, 700);
    }

    .days__subtitle {
      @include font("Open Sans", 16px, 24px, 400);
      text-align: center;
      color: $primary-dark;
    }

    .days-block {
      flex-direction: column;
      width: 98%;

      &__head {
        padding: 15px 15px 5px;
        flex-basis: 132px;
        border-right: 0;
        border-bottom: 2px solid $primary-dark;
        border-top-right-radius: 9px;
        border-bottom-left-radius: 0;
      }

      &__head__button {
        display: none;
      }

      &__head__title {
        line-height: 42px;
      }

      &__info {
        padding: 16px 16px 32px;
      }

      &__info__title {
        line-height: 42px;
      }

      &__info__description {
        margin-top: 11px;
      }

      &__info__button {
        @include font("Open Sans", 20px, 27px, 700);
        display: flex;
        box-sizing: border-box;
        width: 100%;
        height: 43px;
        margin-top: 32px;
        background: $yellow;
        border: 2px solid $black;
        box-shadow: 4px 6px 0 $black;
        border-radius: 6px;
        color: #0b0a0b;
        text-transform: uppercase;
        text-decoration: none;
        text-align: center;
        align-items: center;
        justify-content: center;

        &:hover {
          background-color: $orange;
        }

        &:active {
          box-shadow: 2px 4px 0 $black;
        }
      }
    }
  }

  @media screen and (min-width: 641px) and (max-width: 1024px) {
    .days__title {
      font-size: 36px;
    }
  }

  @media screen and (min-width: 641px) {
    .days .info-image {
      display: block;
      position: absolute;
      bottom: 15px;
      right: 15px;
      z-index: 1;
      &.lights {
        object-fit: cover;
        bottom: 0;
        right: 0;
        position: absolute;
      }

      &.baltic-mining {
        bottom: 0;
        right: 0;
        position: absolute;
      }
    }

    .days.layer {
      & .days-block {
        &__info {
          background-color: unset;
          position: unset;
          border-top: unset;
          &__title {
            z-index: 2;
            position: relative;
          }
        }
      }
    }
  }

  @media screen and (min-width: 641px) and (max-width: 1350px) {
    .days {
      & .info-image {
        display: none !important;
      }
    }
  }
}

.btcpay a {
  color: $primary-dark !important;
}
