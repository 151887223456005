@import "./../../core.scss";

.nav {
  width: 100%;
  max-width: 100%;
  background: $purple;

  &__wrap {
    max-width: 1200px;
    width: 100%;
    height: 100px;

    display: flex;
    justify-content: space-between;
    align-items: center;

    margin: 0 auto;
  }

  &__logo {
    text-decoration: none;

    &__title {
      width: 174px;
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 32px;
      text-transform: uppercase;
      color: $black;
    }
  }

  &__container {
    max-width: unset !important;
    width: 100%;
    padding: 0 6px !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 90px;
    box-sizing: border-box;
  }

  &__right {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__left {
    display: flex;
    align-items: center;
    flex: 2 1 auto;
    gap: 12px;
    align-items: end;
  }

  &__date {
    line-height: 28px;
    color: $black;
  }

  &__link {
    font-size: 16px;
    text-align: center;
    color: $black;
    text-decoration: none;

    &::after {
      content: "";
      display: block;
      height: 1px;
      width: 0;
      background: transparent;
      transition: width 0.7s ease, background-color 0.5s ease;
    }

    &:hover:after {
      width: 100%;
      background: $black;
    }
  }

  &__button {
    display: inline-block;
    box-sizing: border-box;
    padding: 8px 14px;
    background: $yellow;
    border: 2px solid $black;
    box-shadow: 4px 6px 0 $black;
    border-radius: 6px;

    text-decoration: none;
    letter-spacing: 0.01rem;
    font-weight: 700;
    font-size: 20px;
    line-height: 32px;
    text-align: center;
    text-transform: uppercase;
    color: $black;

    &:hover {
      background-color: $orange;
    }

    &:active {
      box-shadow: 2px 4px 0 $black;
    }
  }
}

.menu__icon {
  display: none;
}

.menu__list {
  gap: 26px;
  display: flex;
  align-items: center;
}

.menu__list > li {
  position: relative;
  margin-left: 20px;
  margin-bottom: 0;
}

.menu__close {
  width: 30px;
  height: 30px;
  display: none;

  @include scale_from_to(1024px, $min-size) {
    display: block;
  }
}

@media (min-width: 767px) {
  .menu__body__title {
    display: block;
  }
  .menu__list {
    display: flex;
    align-items: center;
  }
  .menu__list > li {
    padding: 10px 0;
  }
}

@media (max-width: 1024px) {
  .nav {
    height: 110px;
  }

  .nav__button {
    width: 100%;
  }

  .nav__link {
    color: $white;
    font-weight: 700;
    font-size: 20px;
    line-height: 32px;
  }
  .nav__right {
    flex: 0 1 auto;
  }
  .nav__container {
    align-items: flex-start;
    padding: 16px !important;
  }
  .nav__left {
    flex-direction: column;
    align-items: start;
    gap: 0;
  }
  .nav__logo__title {
    width: 227px;
  }
  .menu__list {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 16px;
  }
  .menu__icon {
    z-index: 5;
    display: block;
    position: relative;
    width: 30px;
    height: 18px;
    cursor: pointer;
    margin-top: 10px;
  }
  .menu__icon span,
  .menu__icon::before,
  .menu__icon::after {
    left: 0;
    position: absolute;
    height: 4px;
    width: 100%;
    transition: all 0.3s ease 0s;
    background-color: #fff;
  }
  .menu__icon::before,
  .menu__icon::after {
    content: "";
  }
  .menu__icon::before {
    top: 0;
    background: #0a0a0c;
  }
  .menu__icon::after {
    bottom: 0;
    background: #0a0a0c;
  }
  .menu__icon span {
    top: 50%;
    transform: scale(1) translate(0, -50%);
    background: #0a0a0c;
  }
  .menu__icon._active span {
    transform: scale(0) translate(0, -50%);
  }
  .menu__icon._active::before {
    top: 50%;
    transform: rotate(-45deg) translate(0, -50%);
  }
  .menu__icon._active::after {
    bottom: 50%;
    transform: rotate(45deg) translate(0, 50%);
  }

  .menu__body {
    position: fixed;
    top: 0;
    z-index: 100;
    left: -100%;
    height: 100%;
    width: 100%;
    background-color: rgba(11, 10, 11, 0.6);
    padding: 12px 0 30px 0;
    backdrop-filter: blur(20px);
    transition: left 0.3s ease 0s;
    overflow: auto;

    &__header {
      display: flex;
      justify-content: space-between;
      color: $yellow;
      padding: 0 16px 52px;
    }

    &__title {
      font-weight: 700;
      font-size: 20px;
      line-height: 32px;
      text-transform: uppercase;
    }
  }

  .menu__body._active {
    left: 0;
    width: 100%;
  }
  .menu__list > li {
    flex-wrap: wrap;
    margin-bottom: 30px;
    padding: 30px 0;
  }
  .menu__list > li:last-child {
    margin-bottom: 0;
  }
}

.nav.stickyNav {
  position: sticky;
  top: 0;
  z-index: 32;
  background: #c683f6;
  height: 90px;
}

@media (max-width: 1024px) {
  .nav.stickyNav {
    height: 110px;
  }
}

.nav__logo-image {
  //width="157" height="50"
  display: inline-block;
  width: 157px;
  height: 50px;
  background-size: 157px 50px;
  background-image: url("./logonew.svg");
}
