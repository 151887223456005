@import "./../../core.scss";

.conference-tickets {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 0;
  position: relative;
  min-height: 700px;

  &__container {
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__title {
    position: relative;
    @include font("Contrail One", 66px, 76px, 400);
    text-transform: uppercase;
    text-align: center;
    width: fit-content;
    margin-top: 52px;
    line-height: 76px;
    letter-spacing: -0.012em;
    text-shadow: 4px 4px 0 $primary-dark, -1px -1px 0 $primary-dark,
      1px -1px $primary-dark, -1px 1px $primary-dark, 0px -1px $primary-dark,
      -1px 0px $primary-dark, 0px 1px $primary-dark, 1px 0px $primary-dark;
    color: $yellow;

    &::after {
      content: attr(title);
      text-shadow: 4px 6px 0 $black;
      position: absolute;
      left: 0;
      top: 0;
      width: fit-content;
    }
  }

  &__subtitle {
    @include font("Open Sans", 20px, 32px, 700);
    margin-top: 12px;
    text-align: center;
    color: #0a0a0c;
  }

  &__image {
    position: absolute;
    z-index: -1;
    width: 100%;
    right: 3%;
    top: 18%;
  }

  &__card {
    box-sizing: border-box;
    margin-top: 77px;
    width: 626px;
    background: $primary-black;
    // height: 340px;
    height: 316px;
    border: 2px solid $primary-black;
    box-shadow: 4px 6px 0 $primary-black;
    border-radius: 12px;
    position: relative;

    // &::before {
    //   background: url("./background.jpg");
    //   content: "";
    //   width: 100%;
    //   height: 100%;
    //   display: block;
    //   position: absolute;
    //   opacity: 0.2;
    //   background-position: 100% 90%;
    //   background-repeat: no-repeat;
    //   background-size: cover;
    //   border-radius: 12px;
    // }
  }
  .card {
    &__header {
      padding: 24px 24px 0;
      position: relative;
      border: none;
      display: flex;
      justify-content: space-between;
      height: fit-content;
    }
    &__img {
      position: absolute;
      top: 25px;
      right: 22px;
    }
    &__about-price {
      @include font("Open Sans", 26px, 40px, 700);
      color: #ffffff;
    }
    &__price {
      @include font("Open Sans", 46px, 68px, 700);
      color: $yellow;
    }
    &__elem {
      @include font("Open Sans", 16px, 19px, 400);
      color: #ffffff;
      margin-left: -4px;
      position: relative;

      &::before {
        content: "";
        background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='7.2' y='7.2' width='5.6' height='5.6' rx='2.8' fill='%23FFB52E' stroke='%230B0A0B' stroke-width='.4'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        width: 20px;
        height: 20px;
        display: block;
        margin: -1px 2px 9px 3px;
        padding: 0;
      }
    }
    &__tandc {
      @include font("Open Sans", 12px, 18px, 400);
      color: #ffffff;
      margin-top: 27px;
      padding: 0 24px;
      z-index: 3;
      position: relative;

      &__link {
        color: $yellow;
        cursor: pointer;
        text-decoration: none;
      }
    }
    &__buttons {
      display: flex;
      padding: 0 24px;
      margin-top: 17px;
      gap: 24px;
      justify-content: space-between;

      & .button {
        @include font("Open Sans", 20px, 28px, 700);
        display: inline-flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        cursor: pointer;
        text-transform: uppercase;
        color: $black;
        padding: 6px 20px;
        // width: 272px;
        width: 100%;
        min-height: 48px;
        background: $yellow;
        border-color: $yellow;
        border-radius: 6px;
        text-decoration: none;

        &.disable {
          pointer-events: none;
          color: white;
          background: #2c2c2c;
          border-color: #2c2c2c;
        }

        &:hover {
          background-color: $orange;
          border-color: $orange;
          -webkit-transition: all 500ms linear;
          -ms-transition: all 500ms linear;
          transition: all 500ms linear;
        }
      }
    }
  }

  @media screen and (max-width: 556px) {
    min-height: unset;
    &__container {
      padding: 0 16px;
    }
    &__image {
      display: none;
    }
    &__title {
      font-size: 36px;
      line-height: 48px;
      margin-top: 30px;
      letter-spacing: -0.035em;
    }
    &__subtitle {
      letter-spacing: -0.3px;
      margin-top: 6px;
      font-size: 20px;
      line-height: 32px;
    }
    &__card {
      height: fit-content;
      width: 100%;
      margin-top: 45px;
      border: none;
      box-shadow: none;

      // &::before {
      //   background-position: 84%;
      // }
    }
    .card {
      &__header {
        padding: 16px 16px 0;
      }
      &__img {
        top: 16px;
        left: 5px;
      }
      &__elem {
        line-height: 22px;
      }
      &__buttons {
        flex-direction: column;
        padding: 0px 16px;
        margin-top: 36px;
        .button {
          width: 100%;
          font-size: 16px;
          margin-bottom: 12px;
          &.disable {
            text-wrap: wrap;
          }
        }
      }
      &__tandc {
        margin-top: 13px;
        margin-bottom: 16px;
        padding: 0px 16px;
      }
      &__about-price {
        margin-top: 86px;
        font-size: 20px;
      }
    }
  }

  @media screen and (min-width: 557px) and (max-width: 767px) {
    min-height: 700px;
    &__title {
      max-width: 473px;
    }
    &__image {
      display: none;
    }
    &__subtitle {
      font-size: 20px;
      line-height: 26px;
      margin-top: 10px;
      margin-bottom: 36px;
    }
    &__card {
      width: 100%;
      max-width: 516px;
      margin-top: 18px;
    }
    .card {
      width: 100%;
      &__about-price {
        font-size: 26px;
      }
      &__img {
        top: 16px;
        right: 16px;
      }
      &__buttons {
        .button {
          width: 100%;
          max-width: 100%;
          letter-spacing: 0.01em;
          padding: 6px 14px;
        }
      }
    }
  }
  @media screen and (min-width: 768px) and (max-width: 1024px) {
    min-height: 624px;
    &__title {
      max-width: 473px;
      margin-top: 30px;
    }
    &__card {
      max-width: 516px;
      margin-top: 30px;
      max-height: 324px;
    }
    &__image {
      height: 90%;
      top: 150px;
      right: 0;
      object-fit: cover;
    }
    .card {
      &__header {
        padding: 16px 16px 0;
      }
      &__img {
        top: 18px;
        right: 15px;
      }
      &__buttons {
        padding: 0px 16px;
        margin-top: 20px;
        .button {
          width: 100%;
          min-height: 44px;
        }
      }
      &__tandc {
        padding: 0px 16px;
      }
    }
  }

  @media screen and (min-width: 1441px) {
    height: 916px;
  }

  @media screen and (min-width: 1800px) {
    &__image {
      top: 14%;
      max-height: 98%;
    }
  }
}

.ctbanner-container {
  max-width: 1350px;
  width: 100%;
  box-sizing: border-box;
  margin-top: 5px;
}

.ctbanner {
  box-sizing: border-box;
  width: 100%;
  margin: 62px auto;
  padding: 42px 32px;

  background: $green;
  border: 2px solid $black;
  border-radius: 12px;
  box-shadow: 8px 8px 0 $black;

  display: flex;
  flex-direction: column;
  align-items: center;

  &__title {
    display: inline-block;
    text-align: center;

    color: $purple;

    font-size: 66px;
    font-style: normal;
    font-weight: 400;
    line-height: 76px; /* 115.152% */
    letter-spacing: -1.32px;
    text-transform: uppercase;

    @include title();
  }

  &__text {
    margin: 10px auto 0;

    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px; /* 153.846% */

    color: $primary-dark;
    max-width: 1140px;
    text-align: center;
  }

  &__button {
    margin-top: 24px;
    display: flex;
    justify-content: center;
  }

  &__link {
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px; /* 160% */
    text-transform: uppercase;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }
}

@media screen and (max-width: 1200px) {
  .ctbanner {
    margin: 24px auto;

    &__text {
      font-size: 26px;
      line-height: 40px;
    }

    &__title {
      font-size: 36px;
      line-height: 48px;

      @include titleSecondary();
    }
  }
}

@media screen and (max-width: 768px) {
  .ctbanner-container {
    padding: 0 16px;
  }

  .ctbanner {
    margin: 24px auto;
    padding: 16px;

    &__title {
      display: block;
    }
    &__button {
      width: 100%;
    }
  }
}
