@import "./../../core.scss";
.joinCommunity {
  position: relative;

  max-width: 1351px;
  margin: 62px auto;
  padding: 90px 0 76px;
  background: $yellow;
  border: 2px solid $black;
  border-radius: 12px;
  box-shadow: 8px 8px 0 $black;

  &__title {
    white-space: nowrap;
    text-align: center;
    margin: 0 auto 42px;
    font-size: 66px;
    line-height: 70px;
    color: $yellow;
    z-index: 2;

    @include title();
  }

  &__buttons {
    display: flex;
    gap: 42px;
    justify-content: center;
    padding: 0 40px;
  }

  &__img {
    position: absolute;
    right: 60px;
    bottom: 0;
    object-fit: cover;
    object-position: 100% 100px;
    max-width: 260px;
  }
}

@media screen and (max-width: 1200px) {
  .joinCommunity {
    padding: 96px 0 52px;
    margin: 42px auto;
    &__title {
      font-size: 36px;
      line-height: 48px;
      margin: 0 auto 20px;
      text-shadow: 2px 4px 0px $black;
      -webkit-text-stroke: 1px $black;
      &::after {
        content: attr(title);
        text-shadow: 2px 4px 0px $black;
        position: absolute;
        left: 0;
        top: 0;
        width: fit-content;
      }
    }

    &__img {
      height: 350px;
      right: 0;
      object-fit: cover;
      object-position: 100% 92px;
    }
  }
}

@media screen and (max-width: 1024px) {
  .joinCommunity {
    padding: 130px 0 46px;
    &__buttons {
      flex-direction: column;
      align-items: center;
      &__button {
        width: 216px;
        padding: 8px 16px;
      }
    }
    &__img {
      height: 400px;
      top: 6%;
      right: 0;
      height: 92%;
      object-position: unset;
    }
  }
}
